import request from "@/utils/request";

// 提问答复查询
export function search_question(Id,data) {
         return request({
           url: "/search_question/" + Id,
           method: "POST",
           data: data,
         });
       }
// 提问
export function ask_question(data) {
    return request({
        url: "/ask_question",
        method: "POST",
        data: data,
    });
}
// 回答
export function answer_question(data) {
    return request({
        url: "/answer_question",
        method: "POST",
        data: data,
    });
}
// 删除问题或答复（管理员）
export function delete_question(data) {
    return request({
        url: "/delete_question",
        method: "POST",
        data: data,
    });
}